import { $t } from '@/i18n/index'

// 报表交易类型
export const reportTransactionType = [
  { label: $t('report.operating'), value: 'OPERATING' },
  { label: $t('report.financing'), value: 'FINANCING' },
  { label: $t('report.investing'), value: 'INVESTING' }
]

export const transactionDataOptions = [
  { label: $t('report.completeHistory'), value: 'ALL' },
  { label: $t('report.dataBefore'), value: 'BEFORE' },
  { label: $t('report.dataAfter'), value: 'AFTER' },
  { label: $t('report.dataBetween'), value: 'BETWEEN' }
]

export const journalTypeGstOptions = [
  { label: $t('project.none'), value: 'NONE' },
  { label: $t('project.standardRatedSupplies'), value: 'STANDARD' },
  { label: $t('project.zeroRatedSupplies'), value: 'ZERO' },
  { label: $t('project.exemptSupplies'), value: 'EXEMPT' },
  { label: $t('project.taxablePurchases'), value: 'TAXABLE' }
]

export const auxiliaryCodeTypeOptions = [
  { label: $t('report.treasuryAccount'), value: 'ENTITY_ACCOUNT' },
  { label: $t('common.counterparty'), value: 'COUNTERPARTY' },
  { label: $t('common.customOptions'), value: 'ITEM' }
]

export const counterpartyTypeOptions = [
  { label: $t('report.individual'), value: 'INDIVIDUAL', icon: 'report-contact-Employee', fill: '#FF754E' },
  { label: $t('report.organization'), value: 'ORGANIZATION', icon: 'report-contact-organization', fill: '#7596EB' }
]

export const brokerInventoryTypeOptions = [
  { label: $t('project.none'), value: 'NONE' },
  { label: $t('project.purchase'), value: 'PURCHASE' },
  { label: $t('project.sale'), value: 'SALE' }
]

export const costBasisMethodOptions = [
  { label: $t('project.fifo'), value: 'FIFO' },
  { label: $t('project.costAverage'), value: 'COST_AVERAGE' },
  { label: $t('project.specificId'), value: 'SPECIFIC_ID' }
]

export const chainBrowserMap: any = {
  polygon: 'https://polygonscan.com/tx',
  arbitrum: 'https://arbiscan.io/tx',
  avalanche: 'https://snowtrace.io/tx',
  optimism: 'https://optimistic.etherscan.io/tx',
  ethereum: 'https://etherscan.io/tx',
  bsc: 'https://bscscan.com/tx',
  bitcoin: 'https://www.blockchain.com/btc/tx',
  tron: 'https://tronscan.org/#/transaction',
  arweave: 'https://viewblock.io/arweave/tx',
  astar: 'https://astar.subscan.io/extrinsic',
  bitkub: 'https://www.bkcscan.com/tx'
}

export const reportContactType = [
  { label: $t('report.employee'), value: 'Employee', icon: 'report-contact-Employee', color: '#EF7937' },
  { label: $t('report.vendor'), value: 'Vendor', icon: 'report-contact-Vendor', color: '#0AC18E' },
  { label: $t('report.customer'), value: 'Customer', icon: 'report-contact-Customer', color: '#474DFF' },
  { label: $t('report.investor'), value: 'Investor', icon: 'report-contact-Investor', color: '#FA413F' },
  {
    label: $t('report.community'),
    value: 'Community',
    icon: 'report-contact-Community',
    color: '#C2A633'
  },
  {
    label: $t('report.debtor'),
    value: 'Debtor',
    icon: 'report-contact-Debtor',
    color: '#E6007A'
  },
  {
    label: $t('report.sponsor'),
    value: 'Sponsor',
    icon: 'report-contact-Sponsor',
    color: '#F78580'
  }
]

export const financialsMenuList = ref<any[]>([
  { financialId: 'balance-sheet', name: $t('project.nav.balanceSheet') },
  { financialId: 'income-statement', name: $t('project.nav.incomeStatement') },
  { financialId: 'cash-flow-statement', name: $t('project.nav.cashFlowStatement') },
  { financialId: 'line', name: '' },
  { financialId: 'significant-holdings', name: $t('project.nav.SignificantHoldings') },
  { financialId: 'restrictions', name: $t('project.nav.Restrictions') },
  { financialId: 'roll-forward', name: $t('project.nav.RollForward') }
])

// 报表事件列表
export const reportEventList = [
  // {
  //   categoryNo: '10000',
  //   primaryGrouping: 'Operating',
  //   subGrouping: 'NTFs',
  //   categoryName: 'NFTs primary sales',
  //   cryptoPayments: false,
  //   CostBasis: true,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'in',
  //   fiatDirection: '/',
  //   isHide: true,
  //   CN: 'NFTs 初次销售',
  //   eventColorIndex: 0
  // },
  // {
  //   categoryNo: '10001',
  //   primaryGrouping: 'Operating',
  //   subGrouping: 'NTFs',
  //   categoryName: 'NFTs secondary sales',
  //   cryptoPayments: false,
  //   CostBasis: true,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'in',
  //   fiatDirection: '/',
  //   isHide: true,
  //   CN: 'NFTs 二次销售',
  //   eventColorIndex: 1
  // },
  // {
  //   categoryNo: '10002',
  //   primaryGrouping: 'Operating',
  //   subGrouping: 'NTFs',
  //   categoryName: 'NFTs royalties income',
  //   cryptoPayments: false,
  //   CostBasis: true,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'in',
  //   fiatDirection: '/',
  //   isHide: true,
  //   CN: 'NFTs 版税收入',
  //   eventColorIndex: 2
  // },
  // {
  //   categoryNo: '10003',
  //   primaryGrouping: 'Operating',
  //   subGrouping: 'NTFs',
  //   categoryName: 'Purchase NFTs',
  //   cryptoPayments: true,
  //   CostBasis: true,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'out',
  //   fiatDirection: '/',
  //   isHide: true,
  //   CN: '购买 NFTs',
  //   eventColorIndex: 3
  // },
  // {
  //   categoryNo: '10004',
  //   primaryGrouping: 'Operating',
  //   subGrouping: 'NTFs',
  //   categoryName: 'Mint NFTs',
  //   cryptoPayments: false,
  //   CostBasis: false,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'in',
  //   fiatDirection: '/',
  //   isHide: true,
  //   CN: '铸造 NFTs',
  //   eventColorIndex: 4
  // },
  // {
  //   categoryNo: '10005',
  //   primaryGrouping: 'Operating',
  //   subGrouping: 'NTFs',
  //   categoryName: 'Pay NFTs royalties',
  //   cryptoPayments: true,
  //   CostBasis: true,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'out',
  //   fiatDirection: '/',
  //   isHide: true,
  //   CN: '支付 NFTs 版税',
  //   eventColorIndex: 5
  // },
  {
    categoryNo: '10100',
    primaryGrouping: 'Operating',
    subGrouping: 'Operating income',
    categoryName: 'Commissions income',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: 'in',
    isHide: false,
    CN: '手续费收入',
    eventColorIndex: 6
  },
  {
    categoryNo: '10101',
    primaryGrouping: 'Operating',
    subGrouping: 'Operating income',
    categoryName: 'DAO incentive income',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: 'DAO激励收入',
    eventColorIndex: 7
  },
  {
    categoryNo: '10102',
    primaryGrouping: 'Operating',
    subGrouping: 'Operating income',
    categoryName: 'Sponsorship income',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: 'in',
    isHide: false,
    CN: '赞助收入',
    eventColorIndex: 8
  },
  {
    categoryNo: '10199',
    primaryGrouping: 'Operating',
    subGrouping: 'Operating income',
    categoryName: 'Other operating income',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: 'in',
    isHide: false,
    CN: '其他营业收入',
    eventColorIndex: 9
  },
  {
    categoryNo: '10299',
    primaryGrouping: 'Operating',
    subGrouping: 'Cost of sales',
    categoryName: 'Other cost of sales',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '其他销售成本',
    eventColorIndex: 10
  },
  {
    categoryNo: '10300',
    primaryGrouping: 'Operating',
    subGrouping: 'G&A',
    categoryName: 'Payroll',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '员工工资支出',
    eventColorIndex: 11
  },
  {
    categoryNo: '10301',
    primaryGrouping: 'Operating',
    subGrouping: 'G&A',
    categoryName: 'Research and development',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '研发费用支出',
    eventColorIndex: 12
  },
  {
    categoryNo: '10302',
    primaryGrouping: 'Operating',
    subGrouping: 'G&A',
    categoryName: 'Software & web services',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '软件和网络服务支出',
    eventColorIndex: 13
  },
  {
    categoryNo: '10399',
    primaryGrouping: 'Operating',
    subGrouping: 'G&A',
    categoryName: 'Misc.expenses',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '其他费用支出',
    eventColorIndex: 14
  },
  {
    categoryNo: '10400',
    primaryGrouping: 'Operating',
    subGrouping: 'Exchange platform',
    categoryName: 'Customer deposit funds',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: true,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '用户入金',
    eventColorIndex: 15
  },
  {
    categoryNo: '10401',
    primaryGrouping: 'Operating',
    subGrouping: 'Exchange platform',
    categoryName: 'Customer withdraw funds',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: true,
    tokenDirection: 'out',
    fiatDirection: '',
    isHide: false,
    CN: '用户出金',
    eventColorIndex: 16
  },
  {
    categoryNo: '10402',
    primaryGrouping: 'Operating',
    subGrouping: 'Exchange platform',
    categoryName: 'Funding income',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '',
    isHide: false,
    CN: '资金费收入',
    eventColorIndex: 17
  },
  {
    categoryNo: '10403',
    primaryGrouping: 'Operating',
    subGrouping: 'Exchange platform',
    categoryName: 'Funding fee',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '',
    isHide: false,
    CN: '资金费费用',
    eventColorIndex: 18
  },
  {
    categoryNo: '10404',
    primaryGrouping: 'Operating',
    subGrouping: 'Exchange platform',
    categoryName: 'Customer gain',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '',
    isHide: false,
    CN: '用户做单盈利',
    eventColorIndex: 19
  },
  {
    categoryNo: '10405',
    primaryGrouping: 'Operating',
    subGrouping: 'Exchange platform',
    categoryName: 'Customer loss',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '',
    isHide: false,
    CN: '用户做单亏损',
    eventColorIndex: 20
  },
  {
    categoryNo: '10406',
    primaryGrouping: 'Operating',
    subGrouping: 'Exchange platform',
    categoryName: 'Customer unrealized gain',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '',
    isHide: false,
    CN: '用户做单盈利（已实现）',
    eventColorIndex: 21
  },
  {
    categoryNo: '10407',
    primaryGrouping: 'Operating',
    subGrouping: 'Exchange platform',
    categoryName: 'Customer unrealized loss',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '',
    isHide: false,
    CN: '用户做单盈利（未实现）',
    eventColorIndex: 22
  },
  {
    categoryNo: '10408',
    primaryGrouping: 'Operating',
    subGrouping: 'Exchange platform',
    categoryName: 'Customer assets swap out',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: true,
    tokenDirection: 'out',
    fiatDirection: '/',
    isHide: false,
    CN: '用户资产货币兑换-卖出',
    eventColorIndex: 90
  },
  {
    categoryNo: '10409',
    primaryGrouping: 'Operating',
    subGrouping: 'Exchange platform',
    categoryName: 'Customer assets swap in',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: true,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '用户资产货币兑换-买入',
    eventColorIndex: 91
  },
  {
    categoryNo: '19800',
    primaryGrouping: 'Operating',
    subGrouping: 'Other income',
    categoryName: 'Airdrop',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '空投',
    eventColorIndex: 23
  },
  {
    categoryNo: '19801',
    primaryGrouping: 'Operating',
    subGrouping: 'Other income',
    categoryName: 'Hard folk',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '硬分叉',
    eventColorIndex: 24
  },
  {
    categoryNo: '19802',
    primaryGrouping: 'Operating',
    subGrouping: 'Other income',
    categoryName: 'Chain split',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '链分裂',
    eventColorIndex: 25
  },
  {
    categoryNo: '19803',
    primaryGrouping: 'Operating',
    subGrouping: 'Other income',
    categoryName: 'Grants',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: 'in',
    isHide: false,
    CN: '津贴收入',
    eventColorIndex: 26
  },
  {
    categoryNo: '19899',
    primaryGrouping: 'Operating',
    subGrouping: 'Other income',
    categoryName: 'Misc. revenue',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: 'in',
    isHide: false,
    CN: '其他收入',
    eventColorIndex: 27
  },
  {
    categoryNo: '19900',
    primaryGrouping: 'Operating',
    subGrouping: 'Other expenses',
    categoryName: 'Transaction fee',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '/',
    isHide: false,
    CN: '交易费用',
    eventColorIndex: 28
  },
  {
    categoryNo: '19901',
    primaryGrouping: 'Operating',
    subGrouping: 'Other expenses',
    categoryName: 'Marketing expenses',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '营销费用',
    eventColorIndex: 29
  },
  {
    categoryNo: '19902',
    primaryGrouping: 'Operating',
    subGrouping: 'Other expenses',
    categoryName: 'Commission fee',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: '',
    fiatDirection: '',
    isHide: false,
    CN: '佣金费用',
    eventColorIndex: 30
  },
  {
    categoryNo: '19998',
    primaryGrouping: 'Operating',
    subGrouping: 'Other expenses',
    categoryName: 'Lost/Stolen',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '/',
    isHide: false,
    CN: '丢失/被盗',
    eventColorIndex: 31
  },
  {
    categoryNo: '19999',
    primaryGrouping: 'Operating',
    subGrouping: 'Other expenses',
    categoryName: 'Other expense',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '其他费用',
    eventColorIndex: 32
  },
  {
    categoryNo: '20000',
    primaryGrouping: 'Financing',
    subGrouping: 'Debt',
    categoryName: 'Borrowing',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: true,
    tokenDirection: 'in',
    fiatDirection: 'in',
    isHide: false,
    CN: '借款',
    eventColorIndex: 33
  },
  {
    categoryNo: '20001',
    primaryGrouping: 'Financing',
    subGrouping: 'Debt',
    categoryName: 'Repay borrow',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: true,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '还款',
    eventColorIndex: 34
  },
  {
    categoryNo: '20002',
    primaryGrouping: 'Financing',
    subGrouping: 'Debt',
    categoryName: 'Pay interest expense',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '支付利息',
    eventColorIndex: 35
  },
  {
    categoryNo: '20100',
    primaryGrouping: 'Financing',
    subGrouping: 'Equity',
    categoryName: 'Investment from shareholders',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: 'in',
    isHide: false,
    CN: '投资人打款',
    eventColorIndex: 36
  },
  {
    categoryNo: '20101',
    primaryGrouping: 'Financing',
    subGrouping: 'Equity',
    categoryName: 'Dividends to shareholders',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '支付投资人股利',
    eventColorIndex: 37
  },
  {
    categoryNo: '20102',
    primaryGrouping: 'Financing',
    subGrouping: 'Equity',
    categoryName: 'Shareholders exit',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '投资人退出',
    eventColorIndex: 38
  },
  {
    categoryNo: '30000',
    primaryGrouping: 'Investing',
    subGrouping: 'Token trading',
    categoryName: 'Buy token',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: 'out',
    isHide: false,
    CN: '购买加密货币',
    eventColorIndex: 39
  },
  {
    categoryNo: '30001',
    primaryGrouping: 'Investing',
    subGrouping: 'Token trading',
    categoryName: 'Cashback received',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '收到返现',
    eventColorIndex: 40
  },
  {
    categoryNo: '30002',
    primaryGrouping: 'Investing',
    subGrouping: 'Token trading',
    categoryName: 'Sell token',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'in',
    isHide: false,
    CN: '销售加密货币',
    eventColorIndex: 41
  },
  {
    categoryNo: '30100',
    primaryGrouping: 'Investing',
    subGrouping: 'Swap',
    categoryName: 'Swap-in',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '货币兑换-买入',
    eventColorIndex: 42
  },
  {
    categoryNo: '30101',
    primaryGrouping: 'Investing',
    subGrouping: 'Swap',
    categoryName: 'Swap-out',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '/',
    isHide: false,
    CN: '货币兑换-卖出',
    eventColorIndex: 43
  },
  {
    categoryNo: '30200',
    primaryGrouping: 'Investing',
    subGrouping: 'staking(non-tradable token)',
    categoryName: 'Staking deposit',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '/',
    isHide: false,
    CN: '质押',
    eventColorIndex: 44
  },
  {
    categoryNo: '30201',
    primaryGrouping: 'Investing',
    subGrouping: 'staking(non-tradable token)',
    categoryName: 'Unstaking',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '解除质押',
    eventColorIndex: 45
  },
  {
    categoryNo: '30203',
    primaryGrouping: 'Investing',
    subGrouping: 'staking(non-tradable token)',
    categoryName: 'Receive staking rewards',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '收到质押奖励',
    eventColorIndex: 46
  },
  {
    categoryNo: '30300',
    primaryGrouping: 'Investing',
    subGrouping: 'LP staking',
    categoryName: 'Add liquidity',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '/',
    isHide: false,
    CN: '增加流动性',
    eventColorIndex: 47
  },
  {
    categoryNo: '30301',
    primaryGrouping: 'Investing',
    subGrouping: 'LP staking',
    categoryName: 'Remove liquidity',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '抽取流动性',
    eventColorIndex: 48
  },
  {
    categoryNo: '30302',
    primaryGrouping: 'Investing',
    subGrouping: 'LP staking',
    categoryName: 'Receive LP staking rewards',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '收到流动性质押奖励',
    eventColorIndex: 49
  },
  {
    categoryNo: '30400',
    primaryGrouping: 'Investing',
    subGrouping: 'Farming',
    categoryName: 'Farming deposit',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '/',
    isHide: false,
    CN: '挖矿质押',
    eventColorIndex: 50
  },
  {
    categoryNo: '30401',
    primaryGrouping: 'Investing',
    subGrouping: 'Farming',
    categoryName: 'Remove farming',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '解除挖矿质押',
    eventColorIndex: 51
  },
  {
    categoryNo: '30403',
    primaryGrouping: 'Investing',
    subGrouping: 'Farming',
    categoryName: 'Receive farming rewards',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '收到挖矿奖励',
    eventColorIndex: 52
  },
  {
    categoryNo: '30500',
    primaryGrouping: 'Investing',
    subGrouping: 'Lending',
    categoryName: 'Lending deposit',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '/',
    isHide: false,
    CN: '向借贷协议存款',
    eventColorIndex: 53
  },
  {
    categoryNo: '30501',
    primaryGrouping: 'Investing',
    subGrouping: 'Lending',
    categoryName: 'Lending redeem',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '从借贷协议提款',
    eventColorIndex: 54
  },
  {
    categoryNo: '30503',
    primaryGrouping: 'Investing',
    subGrouping: 'Lending',
    categoryName: 'Receive interest income',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '收到利息收入',
    eventColorIndex: 55
  },
  {
    categoryNo: '30600',
    primaryGrouping: 'Investing',
    subGrouping: 'Custody',
    categoryName: 'Custody deposit',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '/',
    isHide: false,
    CN: '托管存款',
    eventColorIndex: 56
  },
  {
    categoryNo: '30601',
    primaryGrouping: 'Investing',
    subGrouping: 'Custody',
    categoryName: 'Withdraw custody',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '托管取款',
    eventColorIndex: 57
  },
  {
    categoryNo: '30603',
    primaryGrouping: 'Investing',
    subGrouping: 'Custody',
    categoryName: 'Receive custody income',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '收到托管收入',
    eventColorIndex: 58
  },
  {
    categoryNo: '30700',
    primaryGrouping: 'Investing',
    subGrouping: 'Locking',
    categoryName: 'Locking deposit',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '/',
    isHide: false,
    CN: '锁定',
    eventColorIndex: 59
  },
  {
    categoryNo: '30701',
    primaryGrouping: 'Investing',
    subGrouping: 'Locking',
    categoryName: 'Unlock',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '解锁',
    eventColorIndex: 60
  },
  {
    categoryNo: '30703',
    primaryGrouping: 'Investing',
    subGrouping: 'Locking',
    categoryName: 'Receive locking rewards',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '收到锁定奖励',
    eventColorIndex: 61
  },
  {
    categoryNo: '30901',
    primaryGrouping: 'Investing',
    subGrouping: 'Long-term equity investment',
    categoryName: 'Long-term equity investment',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '长期股权投资',
    eventColorIndex: 62
  },
  // {
  //   categoryNo: '30902',
  //   primaryGrouping: 'Investing',
  //   subGrouping: 'Long-term equity investment',
  //   categoryName: 'Exit long-term equity investment',
  //   cryptoPayments: false,
  //   CostBasis: true,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'in',
  //   fiatDirection: 'in',
  //   isHide: true,
  //   CN: '退出长期股权投资',
  //   eventColorIndex: 63
  // },
  {
    categoryNo: '30904',
    primaryGrouping: 'Investing',
    subGrouping: 'Long-term equity investment',
    categoryName: 'Receive dividends',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: 'in',
    isHide: false,
    CN: '收到股利',
    eventColorIndex: 64
  },
  // {
  //   categoryNo: '31000',
  //   primaryGrouping: 'Investing',
  //   subGrouping: 'ICO',
  //   categoryName: 'Pre-ICO investments',
  //   cryptoPayments: true,
  //   CostBasis: true,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'out',
  //   fiatDirection: '/',
  //   isHide: true,
  //   CN: 'Pre-ICO 投资',
  //   eventColorIndex: 65
  // },
  // {
  //   categoryNo: '31001',
  //   primaryGrouping: 'Investing',
  //   subGrouping: 'ICO',
  //   categoryName: 'Investee ICO',
  //   cryptoPayments: false,
  //   CostBasis: true,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'in',
  //   fiatDirection: '/',
  //   isHide: true,
  //   CN: '被投方 ICO',
  //   eventColorIndex: 66
  // },
  // {
  //   categoryNo: '31002',
  //   primaryGrouping: 'Investing',
  //   subGrouping: 'ICO',
  //   categoryName: 'Unlock ICO crypto assets',
  //   cryptoPayments: false,
  //   CostBasis: false,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'in',
  //   fiatDirection: '/',
  //   isHide: true,
  //   CN: '解锁 ICO 资产',
  //   eventColorIndex: 67
  // },
  {
    categoryNo: '31100',
    primaryGrouping: 'Investing',
    subGrouping: 'Loan business',
    categoryName: 'Loan to customers',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '向客户发放贷款',
    eventColorIndex: 68
  },
  // {
  //   categoryNo: '31101',
  //   primaryGrouping: 'Investing',
  //   subGrouping: 'Loan business',
  //   categoryName: 'Customer repayment',
  //   cryptoPayments: false,
  //   CostBasis: true,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'in',
  //   fiatDirection: 'in',
  //   isHide: true,
  //   CN: '客户归还贷款',
  //   eventColorIndex: 69
  // },
  {
    categoryNo: '31102',
    primaryGrouping: 'Investing',
    subGrouping: 'Loan business',
    categoryName: 'Receive loan interest',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: 'in',
    isHide: false,
    CN: '收到贷款利息',
    eventColorIndex: 70
  },
  {
    categoryNo: '31200',
    primaryGrouping: 'Investing',
    subGrouping: 'Customer swap',
    categoryName: 'Customer swap-out',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '客户货币兑换-卖出',
    eventColorIndex: 71
  },
  {
    categoryNo: '31201',
    primaryGrouping: 'Investing',
    subGrouping: 'Customer swap',
    categoryName: 'Customer swap-in',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '/',
    isHide: false,
    CN: '客户货币兑换-买入',
    eventColorIndex: 72
  },
  {
    categoryNo: '31300',
    primaryGrouping: 'Investing',
    subGrouping: 'Banking',
    categoryName: 'Customer deposit',
    cryptoPayments: false,
    CostBasis: true,
    BorrowingCostBasis: true,
    tokenDirection: 'in',
    fiatDirection: '/',
    isHide: false,
    CN: '用户存款',
    eventColorIndex: 73
  },
  {
    categoryNo: '31301',
    primaryGrouping: 'Investing',
    subGrouping: 'Banking',
    categoryName: 'Customer withdraw',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: true,
    tokenDirection: 'out',
    fiatDirection: '/',
    isHide: false,
    CN: '用户取款',
    eventColorIndex: 74
  },
  {
    categoryNo: '31302',
    primaryGrouping: 'Investing',
    subGrouping: 'Banking',
    categoryName: 'Pay interest to customers',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: '/',
    isHide: false,
    CN: '向用户支付利息',
    eventColorIndex: 75
  },
  {
    categoryNo: '39800',
    primaryGrouping: 'Investing',
    subGrouping: 'Purchase fixed assets',
    categoryName: 'Purchase fixed assets',
    cryptoPayments: true,
    CostBasis: true,
    BorrowingCostBasis: false,
    tokenDirection: 'out',
    fiatDirection: 'out',
    isHide: false,
    CN: '购买固定资产',
    eventColorIndex: 76
  },
  // {
  //   categoryNo: '39900',
  //   primaryGrouping: 'Investing',
  //   subGrouping: 'Other investments',
  //   categoryName: 'Other investment',
  //   cryptoPayments: true,
  //   CostBasis: true,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'out',
  //   fiatDirection: 'out',
  //   isHide: false,
  //   CN: '其他投资',
  //   eventColorIndex: 77
  // },
  // {
  //   categoryNo: '39901',
  //   primaryGrouping: 'Investing',
  //   subGrouping: 'Other investments',
  //   categoryName: 'Exit other investment',
  //   cryptoPayments: false,
  //   CostBasis: true,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'in',
  //   fiatDirection: 'in',
  //   isHide: true,
  //   CN: '退出其他投资',
  //   eventColorIndex: 78
  // },
  // {
  //   categoryNo: '39902',
  //   primaryGrouping: 'Investing',
  //   subGrouping: 'Other investments',
  //   categoryName: 'Receive other investment income',
  //   cryptoPayments: false,
  //   CostBasis: true,
  //   BorrowingCostBasis: false,
  //   tokenDirection: 'in',
  //   fiatDirection: 'in',
  //   isHide: false,
  //   CN: '收到其他投资收入',
  //   eventColorIndex: 79
  // },
  {
    categoryNo: '40000',
    primaryGrouping: 'Internal transfer',
    subGrouping: 'Internal transfer',
    categoryName: 'Internal transfer',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: 'internal transfer',
    fiatDirection: 'internal transfer',
    isHide: false,
    CN: '内部交易',
    eventColorIndex: 80
  },
  {
    categoryNo: '99994',
    primaryGrouping: 'Gain/Loss adjusting',
    subGrouping: 'Unrealized gain/loss',
    categoryName: 'Adjusting for unrealized gain/loss - assets',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: '/',
    fiatDirection: '/',
    isHide: false,
    CN: '未实现损益调账-资产',
    eventColorIndex: 81
  },
  {
    categoryNo: '99995',
    primaryGrouping: 'Gain/Loss adjusting',
    subGrouping: 'Realized gain/loss',
    categoryName: 'Adjusting for realized gain/loss - assets',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: '/',
    fiatDirection: '/',
    isHide: false,
    CN: '已实现损益调账-资产',
    eventColorIndex: 82
  },
  {
    categoryNo: '99996',
    primaryGrouping: 'Gain/Loss adjusting',
    subGrouping: 'Unrealized gain/loss',
    categoryName: 'Adjusting for unrealized gain/loss - liabilities',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: '',
    fiatDirection: '',
    isHide: false,
    CN: '未实现损益调账-负债',
    eventColorIndex: 83
  },
  {
    categoryNo: '99997',
    primaryGrouping: 'Gain/Loss adjusting',
    subGrouping: 'Realized gain/loss',
    categoryName: 'Adjusting for realized gain/loss - liabilities',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: '/',
    fiatDirection: '',
    isHide: false,
    CN: '已实现损益调账-负债',
    eventColorIndex: 84
  },
  {
    categoryNo: '99998',
    primaryGrouping: 'Mark to market',
    subGrouping: 'Mark to market',
    categoryName: 'Mark to market - Fiat assets',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: '/',
    fiatDirection: '/',
    isHide: false,
    CN: '公允价值调整-法币资产',
    eventColorIndex: 86
  },
  {
    categoryNo: '99999',
    primaryGrouping: 'Mark to market',
    subGrouping: 'Mark to market',
    categoryName: 'Mark to market - Fiat liabilities',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: '/',
    fiatDirection: '/',
    isHide: false,
    CN: '公允价值调整-法币负债',
    eventColorIndex: 87
  },
  {
    categoryNo: '31400',
    primaryGrouping: 'Investing',
    subGrouping: 'Fiat exchange',
    categoryName: 'Fiat exchange in',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: '/',
    fiatDirection: '/',
    isHide: false,
    CN: '美元兑出',
    eventColorIndex: 100
  },
  {
    categoryNo: '31401',
    primaryGrouping: 'Investing',
    subGrouping: 'Fiat exchange',
    categoryName: 'Fiat exchange out',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: '/',
    fiatDirection: '/',
    isHide: false,
    CN: '美元兑入',
    eventColorIndex: 101
  },
  {
    categoryNo: '50000',
    primaryGrouping: 'Ignore',
    subGrouping: 'Ignore',
    categoryName: 'Ignore',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: '/',
    fiatDirection: '/',
    isHide: false,
    CN: '忽略',
    eventColorIndex: 88
  },
  {
    categoryNo: '00000',
    primaryGrouping: 'No set',
    subGrouping: 'No set',
    categoryName: 'No set',
    cryptoPayments: false,
    CostBasis: false,
    BorrowingCostBasis: false,
    tokenDirection: '',
    fiatDirection: '',
    isHide: false,
    CN: '未设置',
    eventColorIndex: 89
  }
]

// 所有科目列表
export const exportAccountList = [
  {
    accountId: '100',
    accountName: 'Assets'
  },
  {
    accountId: '100-00',
    accountName: 'Cash and cash equivalents'
  },
  {
    accountId: '100-01',
    accountName: 'Crypto assets'
  },
  {
    accountId: '100-01-00',
    accountName: 'Unrestricted crypto assets'
  },
  {
    accountId: '100-01-01',
    accountName: 'Restricted crypto assets'
  },
  {
    accountId: '100-01-01-00',
    accountName: 'Crypto assets restricted by staking protocols'
  },
  {
    accountId: '100-01-01-01',
    accountName: 'Crypto assets restricted by liquidity providing protoclos'
  },
  {
    accountId: '100-01-01-02',
    accountName: 'Crypto assets restricted by farming protocols'
  },
  {
    accountId: '100-01-01-03',
    accountName: 'Crypto assets restricted by lender'
  },
  {
    accountId: '100-01-01-04',
    accountName: 'Crypto assets restricted by custodians'
  },
  {
    accountId: '100-01-01-05',
    accountName: 'Crypto assets restricted by locking protocols'
  },
  {
    accountId: '100-02',
    accountName: 'Receivables'
  },
  {
    accountId: '100-01-02',
    accountName: 'Customer crypto assets'
  },
  {
    accountId: '100-02-01',
    accountName: 'Crypto receivables'
  },
  {
    accountId: '100-02-01-00',
    accountName: 'Staking rewards receivalbe'
  },
  {
    accountId: '100-02-01-01',
    accountName: 'LP staking rewards receivalbe'
  },
  {
    accountId: '100-02-01-02',
    accountName: 'Farming rewards receivalbe'
  },
  {
    accountId: '100-02-01-03',
    accountName: 'Locking rewards receivalbe'
  },
  {
    accountId: '100-02-01-04',
    accountName: 'Interest receivable'
  },
  {
    accountId: '100-02-01-05',
    accountName: 'Dividends receivable'
  },
  {
    accountId: '100-02-01-06',
    accountName: 'Loan receivable'
  },
  {
    accountId: '100-02-01-06',
    accountName: 'Accounts receivalbe'
  },
  {
    accountId: '100-02-99',
    accountName: 'Other receivables'
  },
  {
    accountId: '100-03',
    accountName: 'Inventory'
  },
  {
    accountId: '100-04',
    accountName: 'Prepaid expenses'
  },
  {
    accountId: '100-05',
    accountName: 'Investments'
  },
  {
    accountId: '100-05-00',
    accountName: 'Pre-ICOs'
  },
  {
    accountId: '100-05-02',
    accountName: 'Long-term Investments'
  },
  {
    accountId: '100-06',
    accountName: 'Property, plant and equipment'
  },
  {
    accountId: '100-07',
    accountName: 'NFTs, net'
  },
  {
    accountId: '100-07-00',
    accountName: 'NFTs'
  },
  {
    accountId: '100-07-01',
    accountName: 'Accumulated impairment - NFTs'
  },
  {
    accountId: '200',
    accountName: 'Liabilities'
  },
  {
    accountId: '200-00',
    accountName: 'Payables'
  },
  {
    accountId: '200-00-00',
    accountName: 'Crypto payables'
  },
  {
    accountId: '200-00-00-00',
    accountName: 'Accounts payable'
  },
  {
    accountId: '200-00-00-01',
    accountName: 'Dividends payable'
  },
  {
    accountId: '200-00-00-02',
    accountName: 'Interest payable'
  },
  {
    accountId: '200-00-99',
    accountName: 'Other payables'
  },
  {
    accountId: '200-01',
    accountName: 'Accrued expenses'
  },
  {
    accountId: '200-02',
    accountName: 'Financial liabilities'
  },
  {
    accountId: '200-02-00',
    accountName: 'Crypto borrowings'
  },
  {
    accountId: '200-02-01',
    accountName: 'Deposit from customers'
  },
  {
    accountId: '200-02-02',
    accountName: 'Customer crypto liabilities'
  },
  {
    accountId: '200-02-99',
    accountName: 'Other debts and borrowings'
  },
  {
    accountId: '300',
    accountName: 'Equity'
  },
  {
    accountId: '300-00',
    accountName: "Owner's equity "
  },
  {
    accountId: '300-01',
    accountName: 'Retained earnings'
  },
  {
    accountId: '400',
    accountName: 'Revenue'
  },
  {
    accountId: '400-00',
    accountName: 'Operating income'
  },
  {
    accountId: '400-00-00',
    accountName: 'NFTs primary sales'
  },
  {
    accountId: '400-00-01',
    accountName: 'NFTs secondary sales'
  },
  {
    accountId: '400-00-02',
    accountName: 'NFTs royalties'
  },
  {
    accountId: '400-00-03',
    accountName: 'Commissions income'
  },
  {
    accountId: '400-00-04',
    accountName: 'DAO incentive income'
  },
  {
    accountId: '400-00-05',
    accountName: 'Sponsorship income'
  },
  {
    accountId: '400-00-06',
    accountName: 'Mint income'
  },
  {
    accountId: '400-00-07',
    accountName: 'Interest income'
  },
  {
    accountId: '400-00-99',
    accountName: 'Other operating income'
  },
  {
    accountId: '400-01-00',
    accountName: 'Net PnL from traders'
  },
  {
    accountId: '400-01-01',
    accountName: 'Net unrealized PnL from traders'
  },
  {
    accountId: '500',
    accountName: 'Expenses'
  },
  {
    accountId: '500-00',
    accountName: 'Cost of sales'
  },
  {
    accountId: '500-00-00',
    accountName: 'Cost of NFTs'
  },
  {
    accountId: '500-00-00-00',
    accountName: 'Cost of NFTs'
  },
  {
    accountId: '500-00-00-01',
    accountName: 'Cost of NFTs - royalties'
  },
  {
    accountId: '500-00-99',
    accountName: 'Other cost of sales'
  },
  {
    accountId: '500-01',
    accountName: 'General and administrative expenses'
  },
  {
    accountId: '500-01-00',
    accountName: 'Payroll and staff related costs'
  },
  {
    accountId: '500-01-01',
    accountName: 'Research and development'
  },
  {
    accountId: '500-01-02',
    accountName: 'Software & web services'
  },
  {
    accountId: '500-01-99',
    accountName: 'Miscellaneous operating expenses'
  },
  {
    accountId: '500-02',
    accountName: 'Marketing expense'
  },
  {
    accountId: '500-03',
    accountName: 'Transaction cost'
  },
  {
    accountId: '500-04',
    accountName: 'Impairment cost'
  },
  {
    accountId: '500-04-00',
    accountName: 'Impairment cost - NFTs'
  },
  {
    accountId: '500-04-01',
    accountName: 'Impairment cost - Long-term investments'
  },
  {
    accountId: '500-05',
    accountName: 'Royalties cost'
  },
  {
    accountId: '500-06',
    accountName: 'Commission fee'
  },
  {
    accountId: '500-07',
    accountName: 'Interest expenses'
  },
  {
    accountId: '600',
    accountName: 'Other income and expenses'
  },
  {
    accountId: '600-00',
    accountName: 'Other income'
  },
  {
    accountId: '600-00-00',
    accountName: 'Financial income'
  },
  {
    accountId: '600-00-00-00',
    accountName: 'Interest income'
  },
  {
    accountId: '600-00-00-01',
    accountName: 'Dividends income'
  },
  {
    accountId: '600-00-00-02',
    accountName: 'Staking rewards'
  },
  {
    accountId: '600-00-00-03',
    accountName: 'LP staking rewards'
  },
  {
    accountId: '600-00-00-04',
    accountName: 'Farming rewards'
  },
  {
    accountId: '600-00-00-05',
    accountName: 'Custody income'
  },
  {
    accountId: '600-00-00-06',
    accountName: 'Locking rewards'
  },
  {
    accountId: '600-00-00-99',
    accountName: 'Other investment income'
  },
  {
    accountId: '600-00-01',
    accountName: 'Other income'
  },
  {
    accountId: '600-00-01-00',
    accountName: 'Airdrop'
  },
  {
    accountId: '600-00-01-01',
    accountName: 'Hard fork'
  },
  {
    accountId: '600-00-01-02',
    accountName: 'Chain split'
  },
  {
    accountId: '600-00-01-03',
    accountName: 'Grant income'
  },
  {
    accountId: '600-00-99',
    accountName: 'Other miscellaneous income'
  },
  {
    accountId: '600-01',
    accountName: 'Other expenses'
  },
  {
    accountId: '600-01-00',
    accountName: 'Financial expenses'
  },
  {
    accountId: '600-01-00-00',
    accountName: 'Interest expense'
  },
  {
    accountId: '600-01-00-02',
    accountName: 'hacked'
  },
  {
    accountId: '600-01-00-99',
    accountName: 'Other finance expenses'
  },
  {
    accountId: '600-01-01',
    accountName: 'lost/stolen'
  },
  {
    accountId: '600-01-99',
    accountName: 'Other miscellaneous expenses'
  },
  {
    accountId: '600-02',
    accountName: 'Gains And Losses'
  },
  {
    accountId: '600-02-00',
    accountName: 'Gain (Loss) on crypto activities'
  },
  {
    accountId: '600-02-01',
    accountName: 'Fiat exchange gain (loss)'
  },
  {
    accountId: '600-02-02',
    accountName: 'Other gains (Losses) '
  },
  {
    accountId: '600-02-03',
    accountName: 'Unrealized gain (loss) - crypto fair value measurement'
  },
  {
    accountId: '600-02-04',
    accountName: 'Other unrealized gains and (losses)'
  },
  {
    accountId: '600-02-05',
    accountName: 'Gain (Loss) on investments'
  }
]

// 报表字段列表
export const processTableFieldList: any = {
  holdings: {
    fieldGroupList: [
      {
        fieldId: 'holdings-assets',
        name: 'Assets',
        type: 'Currency'
      },
      {
        fieldId: 'holdings-quantity',
        name: 'Quantity',
        type: 'Number'
      },
      {
        fieldId: 'holdings-const-basis',
        name: 'Cost Basis',
        type: 'Number'
      },
      {
        fieldId: 'holdings-fair-value',
        name: 'Fair Value',
        type: 'Number'
      }
    ]
  },
  generalLedger: {
    fieldGroupList: [
      {
        fieldId: 'general-ledger-date-time',
        name: 'Date',
        type: 'Date'
      },
      {
        fieldId: 'general-ledger-auxiliary-code',
        name: 'Auxiliary code',
        type: 'AuxiliaryCode'
      },
      {
        fieldId: 'general-ledger-journal',
        name: 'Journal',
        type: 'Text'
      },
      {
        fieldId: 'general-ledger-status',
        name: 'TrailBalanceStatus',
        type: 'Status'
      },
      {
        fieldId: 'general-ledger-currency',
        name: 'Currency',
        type: 'Currency'
      },
      {
        fieldId: 'general-ledger-opening-balance',
        name: 'Opening Balance',
        type: 'Balance'
      },
      {
        fieldId: 'general-ledger-debit',
        name: 'Debit',
        type: 'Borrow'
      },
      {
        fieldId: 'general-ledger-credit',
        name: 'Credit',
        type: 'Borrow'
      },
      {
        fieldId: 'general-ledger-closing-balance',
        name: 'Closing Balance',
        type: 'Balance'
      }
    ]
  },
  accountBalance: {
    fieldGroupList: [
      {
        fieldId: 'account-balance-account',
        name: 'Account',
        type: 'Account'
      },
      {
        fieldId: 'account-balance-opening-balance',
        name: 'Opening Balance',
        type: 'Balance'
      },
      {
        fieldId: 'account-balance-debit',
        name: 'Debit',
        type: 'Borrow'
      },
      {
        fieldId: 'account-balance-credit',
        name: 'Credit',
        type: 'Borrow'
      },
      {
        fieldId: 'account-balance-closing-balance',
        name: 'Closing Balance',
        type: 'Balance'
      }
    ]
  },
  accounts: {
    fieldGroupList: [
      {
        fieldId: 'accounts-name',
        name: 'accountName',
        type: 'Text'
      },
      {
        fieldId: 'accounts-data-source-provider',
        name: 'Datasource provider',
        type: 'DataSourceProvider'
      }
    ]
  },
  derivative: {
    fieldGroupList: [
      {
        fieldId: 'derivative-asset-symbol',
        name: 'Asset Symbol',
        type: 'Text'
      },
      {
        fieldId: 'derivative-margin-asset',
        name: 'Margin Asset',
        type: 'Text'
      },
      {
        fieldId: 'derivative-position-side',
        name: 'Position Side',
        type: 'Text'
      },
      {
        fieldId: 'derivative-amount',
        name: 'Amount',
        type: 'Text'
      },
      {
        fieldId: 'derivative-unrealized-P&L',
        name: 'Unrealized P&L',
        type: 'Borrow'
      }
    ]
  },
  deFiPosition: {
    fieldGroupList: [
      {
        fieldId: 'defi-account-Procotol',
        name: 'Account/Procotol',
        type: 'Text'
      },
      {
        fieldId: 'defi-type',
        name: 'Type',
        type: 'Text'
      },
      {
        fieldId: 'defi-pool',
        name: 'Pool',
        type: 'Text'
      },
      {
        fieldId: 'defi-description',
        name: 'Description',
        type: 'Text'
      },
      {
        fieldId: 'defi-supply',
        name: 'Supply',
        type: 'Borrow'
      },
      {
        fieldId: 'defi-pending-rewards',
        name: 'Pending Rewards',
        type: 'Borrow'
      },
      {
        fieldId: 'defi-borrow',
        name: 'Borrow',
        type: 'Borrow'
      },
      {
        fieldId: `defi-total`,
        name: 'Total',
        type: 'Number'
      }
    ]
  }
}
